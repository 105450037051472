@import url("https://fonts.googleapis.com/css2?family=Nunito&family=Source+Sans+Pro&family=Lato&family=Roboto");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.backimage {
  background-image: url("../images/photos/Acc_bg.png");
  margin: 0;
  background-attachment: fixed;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: auto;
}

.home-bg {
  background-image: url("https://docs-assets.katomaran.tech/images/typing/lesson/2022/02/Frame_3.png");
  background-attachment: fixed;
  z-index: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.lesson-bg {
  background-attachment: fixed;
  z-index: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
}
