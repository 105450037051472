progress {
  text-align: center;
  height: 1.5em;
  width: 100%;
  -webkit-appearance: none;
  border: none;
  position: relative;
}
progress:before {
  content: attr(data-label);
  vertical-align: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 4px;
  font-size: 12px;
  font-family: "Nunito";
  font-weight: 300;
}
progress::-moz-progress-bar {
  content: attr(data-label);
  background: #8dc63f;
  text-align: center;
  font-size: 12px;
  padding: 5px;
  border-radius: 3px 0px 0px 3px;
}

progress::-webkit-progress-value {
  background: #8dc63f;
  text-align: center;
  font-size: 12px;
  padding: 5px;
  border-radius: 3px 0px 0px 3px;
}

progress::-webkit-progress-bar {
  background-color: #cfcfcf;
  border-radius: 3px;
}

.group-rectangle {
  padding: 50px 30px;
  background: rgba(161, 92, 92, 0.1);
  background-image: url("../../images/svg/backgroungImage.svg");
  border-top: 1px solid rgba(255, 255, 255, 0.6);
  border-left: 1px solid rgba(255, 255, 255, 0.6);
  box-sizing: border-box;
  backdrop-filter: blur(20px);
  border-radius: 32px;
  background-attachment: fixed;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.15);
  width: 80%;
  box-sizing: border-box;
  -webkit-backdrop-filter: blur(20px);
  margin: auto;
  background: linear-gradient(
    106.25deg,
    rgba(255, 255, 255, 0.48) 5.86%,
    rgba(255, 255, 255, 0.276) 85.48%
  );
}

.profile-icon {
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  border-radius: 50%;
  height: 40px;
  min-width: 40px;
  padding: 3px;
  text-align: center;
}

.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-right: 18px;
  align-items: flex-end;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: -5px;
  background-color: #f9f9f9;
  min-width: 180px;
  box-shadow: 4px 4px 27px rgba(0, 0, 0, 0.15);
  z-index: 1;
  top: 50px;
  border-radius: 5px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.progress {
  background-color: #cfcfcf;
  border-radius: 3px;
  font-size: 12px;
  font-family: "Nunito";
  font-weight: 300;
}

.progress-inloader {
  background-color: #8dc63f;
  text-align: center;
}

.rectangle {
  margin-left: 130px;
  width: 10px;
  height: 10px;
  background-color: #f9f9f9;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-top: -5px;
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .group-rectangle {
    width: 90%;
  }
  .TabSize {
    width: 23vw !important;
    padding: 0 !important;
  }
  .TabActiveColor {
    width: 10rem !important;
  }
  .AchievementData {
    gap: 27px !important;
  }
}