.test-form-container {
  border: 1.5px solid #ffffff;
  box-sizing: border-box;
  /* background: rgba(255, 255, 255, 0.2); */
  background: linear-gradient(
    106.25deg,
    rgba(255, 255, 255, 0.48) 5.86%,
    rgba(255, 255, 255, 0.276) 85.48%
  );
  border-radius: 15px;
  width: 80%;
  margin: 0 auto;
  padding: 35px;
  position: relative;
}

.test-form-description {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #635198;
  margin-bottom: 25px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.time-limits-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-direction: row;
  margin-bottom: 30px;
}

.test-form-title {
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #000000;
  margin: 0px auto 10px;
}

.test-content {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 45px;
  letter-spacing: 0.13em;
  color: #635198;
  margin: 35px 0;
  display: flex;
  flex-wrap: wrap;
  max-height: 300px;
  overflow: hidden;
  height: 235px;
}

.select-timelimit-font {
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #000000;
  margin-bottom: 20px;
  text-align: left;
}

.time-limit-button {
  width: 195px;
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 41px;
  text-align: center;
  letter-spacing: 0.02em;
  border-radius: 45px;
  color: #313149;
  padding: 10px;
  display: inline-block;
  margin: 0;
  position: relative;
  z-index: 0;
  cursor: pointer;
}

.time-limit-button::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2px;
  border-radius: 40px;
  background: linear-gradient(
    to right,
    #98a2ff -0.86%,
    #c29dff 48.52%,
    #f198ff 100%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.time-limit-button-text {
  background: -webkit-linear-gradient(
    to bottom,
    #98a2ff -0.86%,
    #c29dff 48.52%,
    #f198ff 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.test-type-container {
  text-align: center;
  margin-bottom: 30px;
}

.create-first-input-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 70px;
  margin-bottom: 30px;
  align-items: center;
}

.create-first-input-section > input {
  padding: 15px;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  text-align: left;
  letter-spacing: 0.02em;
  position: relative;
  height: 50px;
  border: 1px solid lightgray;
  color: black;
}

.create-first-input-section > select {
  width: 450px;
  background: none;
  padding: 15px;
  border-radius: 40px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  text-align: left;
  letter-spacing: 0.02em;
  color: #635198;
  position: relative;
  z-index: 0;
}

.create-first-input-section > input::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2px;
  border-radius: 40px;
}

.create-first-input-section > select::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2px;
  border-radius: 40px;
}

.create-textarea-container {
  margin-bottom: 30px;
}

.create-textarea-container {
  margin: 30px 0px;
}

.create-textarea-container > textarea {
  width: 100%;
}

.create-submit {
  text-align: center;
}

.test-stats-container {
  display: flex;
  justify-content: space-between;
}

.test-stats-title {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 32px;
  text-align: center;
  color: #000000;
  margin-left: 5px;
}

.test-stats-value {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 55px;
  color: #635198;
}

.test-stats-unit {
  font-size: 18px;
  font-weight: 400;
}

.test-success-card {
  position: absolute;
  width: 700px;
  left: 200px;
  top: 270px;
  padding: 30px;
  background: #ffffff;
  box-sizing: border-box;
  backdrop-filter: blur(20px);
  border-radius: 15px;
  border: "2px solid";
  z-index: 1;
  border: 1px solid lightgrey;
}

.success-card-title {
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #000000;
}

.success-card-description {
  margin-bottom: 30px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: #635198;
}

.test-card-title-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.test-link-container {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #000000;
  background: #e6e3e3;
  padding: 0px 10px;
  border-radius: 15px 0px 15px 15px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.test-link-container > .copy-img {
  width: 50px;
}

.test-link-container > .copy-img > img {
  width: 28px;
  padding: 10px 0px;
}

.test-link-line {
  height: 100%;
  border: 1px solid #635198;
  position: absolute;
  right: 60px;
  top: 0;
}

.test-link-line > img {
  cursor: pointer;
}
