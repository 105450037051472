:root {
  --speed_percentage: 0turn;
  --accuarcy_percentage: 0turn;
  --typos_percentage: 0turn;
}
.lesson-page-header {
  margin: auto;
  width: 60%;
}
.text-content-container {
  width: 80%;
  margin: auto;
  margin-bottom: 4%;
  padding: 40px 40px 90px;
  border-radius: 32px;
  background: linear-gradient(
    106.25deg,
    rgba(255, 255, 255, 0.48) 5.86%,
    rgba(255, 255, 255, 0.276) 85.48%
  );
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
}
.lesson-page-header-blur {
  filter: blur(12px);
}
.text-stats-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.lesson-name-block {
  font-family: Nunito;
  font-weight: 700;
  font-size: 20px;
  font-weight: bold;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ff0000;
}
.stats-container {
  display: flex;
  gap: 30px;
  font-family: Nunito;
}
.lesson-typos,
.lesson-wpm {
  margin: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 27.28px;
}
.lesson-typos > span,
.lesson-wpm > span {
  margin: 0;
  font-weight: 700;
  line-height: 34.1px;
  margin-left: 10px;
}
.text-content-inner-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}
.characters-container {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.character-green {
  border-bottom: 2px solid #639d35;
  border-radius: 0;
  background-color: #cbe8b5;
}
.character-red {
  border-bottom: 2px solid #c61837;
  background-color: #f4c3c3;
}
.character {
  border-radius: 4px;
  font-family: Nunito;
  font-size: 45px;
  font-weight: 600;
  width: 75px;
  text-align: center;
}
.words-container {
  font-family: Nunito;
  font-size: 28px;
  border-radius: 4px;
  letter-spacing: 2px;
  word-spacing: 6px;
  line-height: 45px;
  overflow: hidden;
  height: 225px;
}
.words-container p {
  transition: margin 1000ms ease-in-out;
}
.word-green {
  background-color: lightgreen;
}
.word-red {
  background-color: lightsalmon;
}
.word-blue {
  border-bottom: 2px solid blue;
}
.keyboard-container {
  width: 700px;
  margin: auto;
  position: relative;
}

.para-container {
  display: block;
  border-radius: 4px;
  font-family: Nunito;
  font-size: 45px;
  font-weight: 600;
  text-align: left;
}
.hg-rows {
  padding: 2%;
}
.text-correct-bg {
  color: #68be62;
}
.text-wrong-bg {
  color: #ff0000;
}
.focus-next-letter {
  color: #2788ce;
}
.popupCard {
  font-weight: bold;
  border: 3px solid #f1f1f1;
  transform: translate(-50%, -50%);
  z-index: 2;
  max-width: 65vw;
  max-height: 54vh;
  text-align: center;
}
.popupbackground {
  margin: 0;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: auto;
  background-color: #2E384E;
}
.successStar {
  margin: 35px;
  width: 94px;
  box-shadow: 1px 200px 25px -106px rgba(202,83,83,0.69);
}
.group {
  padding: 13px 0px 0px 0px;
  box-sizing: border-box;
  position: relative;
}
.success-refresh-btn {
  text-align: center;
  padding: 5px 20px;
  cursor: pointer;
  position: absolute;
  right: 50px;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 22px;
  font-weight: 600;
}
.success-star-container{
  text-align: center;
  min-height: 300px;
  padding-top: 30px;
}
.group2 { 
  box-sizing: border-box;
  width: 100%;
  z-index: 1;
}
.progress-whole-container {
  display: flex;
  justify-content: space-around;
  text-align: center;
  font-weight: 800;
  font-family: Nunito;
  font-size: 16px;
  width: 65%;
  margin: 0 auto;
}
.progress-container {
  width: 100%;
  max-width: 200px;
}
.progress-inner-container {
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  background: #56627A;
  position: relative;
  border-top-left-radius: 100% 200%;
  border-top-right-radius: 100% 200%;
  overflow: hidden;
}
.progress-fill {
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: 100%;
  transform-origin: center top;
}
.progress-fill-speed{
  transform: rotate(0turn);
  animation: speed_animated 0.9s cubic-bezier(0.9,0.5,0.5,1) forwards;
}
.progress-fill-accuarcy{
  transform: rotate(0turn);
  animation: accuarcy_animated 0.9s cubic-bezier(0.9,0.5,0.5,1) forwards;
}
.progress-fill-typos{
  transform: rotate(0turn);
  animation: typos_animated 0.9s cubic-bezier(0.9,0.5,0.5,1) forwards;
}
@keyframes speed_animated {
  100% {
    transform: rotate(var(--speed_percentage));
  }
}
@keyframes accuarcy_animated {
  100% {
    transform: rotate(var(--accuarcy_percentage));
  }
}
@keyframes typos_animated {
  100% {
    transform: rotate(var(--typos_percentage));
  }
}
.progress-cover {
  width: 80%;
  height: 150%;
  background-color: #2E384E;
  border-radius: 50%;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%);
}
.progress-des {
  font-size: 24px;
  font-weight: 700;
}
.bar {
  width: 65%;
  margin: 0 auto;
  padding-top: 150px;
}
.progress-line {
  width: 100%;
  height: 13px;
  background-color: #56627A;
  position: relative;
  border-radius: 50px;
}
@keyframes animate {
  100% {
    transform: scaleX(1);
  }
}
.progress-line span {
  height: 100%;
  width: 80%;
  position: absolute;
  background: linear-gradient(to left, #E1F23D, #B1B844);
  border-radius: 50px;
  transform: scaleX(0);
  transform-origin: left;
  animation: animate 0.5s cubic-bezier(1,0,0.5,1) forwards;
}
.progress-line span::before {
  content: '';
  position: absolute;
  background: url("../../images/photos/lesson-circle.png");
  right: -5px;
  top: -56px;
  width: 16px;
  height: 16px;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.progress-line span::after {
  content: '';
  position: absolute;
  border-left: 4px solid white;
  right: 1px;
  top: -41px;
  height: 35px;
  border-radius: 10px;
}
.progress-content {
  position: absolute;
  top: -59px;
  left: 80%;
  padding-left: 15px;
  transform: scaleX(0);
  transform-origin: left;
  animation: animate 0.5s 0.5s cubic-bezier(1,0,0.5,1) forwards;
}
.progress-content-title {
  font-size: 16px;
  font-weight: 400;
}
.progress-content-desc {
  font-size: 18px;
  font-weight: 600;
}
.successpage-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  gap: 100px;
}
.lesson-success-btn{
  width: 12rem;
  font-size: 28px;
  font-family: Source Sans Pro;
  font-weight: 700;
  height: 4rem;
  color: #fff;
  border-radius: 40px;
  border: 1px solid #fff;
  background-color: #2E384E;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.lesson-success-btn > span:hover {
  text-decoration: none !important;
}
.lesson-success-btn:hover {
  color: #5E812B;
  background-color: #fff;
  text-decoration: none;
  border: 1px solid #fff;
  cursor: pointer;
  text-decoration: none;
}
.lesson-next-btn {
  width: 12rem;
  background: #fff;
  font-size: 28px;
  color: #5E812B;
  border: 1px solid #fff;
  font-family: Source Sans Pro;
  font-weight: 700;
  height: 4rem;
  border-radius: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.next-icon {
  display: flex;
}
.next-icon > svg {
  stroke: #5E812B !important;
}
.lesson-next-btn:hover {
  color: #fff;
  background-color: #2E384E;
  text-decoration: none;
  border: 1px solid #fff;
  cursor: pointer;
}
.lesson-next-btn:hover .next-icon > svg {
  stroke: #fff !important;
}
#firstStar {
  margin: 0px 0px -35px 0px;
}
#lastStar {
  margin: 0px 0px -35px 0px;
}
.home:link {
  text-decoration: none;
}
.home:hover {
  text-decoration: none;
  color: #15646f;
}
.home {
  color: #15646f !important;
}

/* Blinking Effect */
.character-blue {
  border-bottom: 2px solid blue;
  -webkit-animation: 0.75s blink step-end infinite;
  -moz-animation: 0.75s blink step-end infinite;
  -ms-animation: 0.75s blink step-end infinite;
  -o-animation: 0.75s blink step-end infinite;
  animation: 0.75s blink step-end infinite;
}

@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: blue;
  }
}

@-moz-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: blue;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: blue;
  }
}

@-ms-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: blue;
  }
}

@-o-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: blue;
  }
}

@media screen and (max-width: 1400px) {
  .word-font {
    font-size: 27px;
  }
  .text-content-container {
    width: 85%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .word-font {
    font-size: 27px;
  }
  .text-content-container {
    width: 90%;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
  .word-font {
    font-size: 24px;
  }
  .text-content-container {
    width: 90%;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1100px) {
  .word-font {
    font-size: 24px;
  }
  .text-content-container {
    width: 95%;
  }
}

@media screen and (max-width: 1035px) {
  .word-font {
    font-size: 21px;
  }
  .text-content-container {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .word-font {
    font-size: 18px;
  }
  .text-content-container {
    width: 100%;
  }
}

@media screen and (max-height: 900px) {
  .words-container {
    height: 180px;
  }
}

@media screen and (max-height: 750px) {
  .words-container {
    height: 135px;
  }

  .text-content-container {
    margin-bottom: 1%;
    padding: 40px 40px 45px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .group2 {
    width: 80% !important;
  }
  .successpage-btn {
    padding: 50px 0px !important;
  }
  .test-content .word-font {
    font-size: 48%;
  }
  .test-form-container {
    width: 90% !important;
  }
  .test-stats-title {
    font-size: 25px !important;
    line-height: 30px !important;
  }
  .test-stats-value {
    font-size: 20px !important;
  }
  .test-icon-container {
    display: flex;
    align-items: center;
  }
  .test-success-center-card {
    flex-direction: column;
  }
  .success-refresh-btn {
    right: -45px;
  }
  .progress-whole-container {
    width: 90%;
    gap: 10px;
  }
  .bar {
    width: 98%;
  }
}

@media screen and (min-width: 320px) and (max-width: 639px) {
  .text-content-container {
    width: 100%;
  }
  .character {
    font-size: 31px;
  }
  .text-stats-container {
    flex-direction: column;
  }
  .characters-container {
    flex-wrap: nowrap;
    overflow: hidden;
    gap: 15px;
  }
  .keyboard-container {
    width: 402px;
  }
  .group2 {
    width: 95%;
  }
  .progress-whole-container {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
  .successpage-btn {
    flex-direction: column;
    gap: 20px;
  }
  .success-refresh-btn {
    right: 30%;
    bottom: 30px;    
  }
}