.left-rest-to-rest-container {
  position: absolute !important;
  top: 23% !important;
  left: -7% !important;
  width: 100% !important;
}

.left-rest {
  width: 60% !important;
  opacity: 0.8;
}

.left-rest-to-q-container {
  position: absolute !important;
  top: 0% !important;
  left: -11.5% !important;
  width: 100% !important;
}

.left-q {
  width: 63% !important;
  opacity: 0.8;
}

.left-rest-to-w-container {
  position: absolute !important;
  top: 6% !important;
  left: -9% !important;
  width: 100% !important;
}

.left-w {
  width: 61% !important;
  opacity: 0.8;
}

.left-rest-to-e-container {
  position: absolute !important;
  top: 5% !important;
  left: -9% !important;
  width: 100% !important;
}

.left-e {
  width: 62% !important;
  opacity: 0.8;
}

.left-rest-to-r-container {
  position: absolute !important;
  top: 11% !important;
  left: -8% !important;
  width: 100% !important;
}

.left-r {
  width: 58% !important;
  opacity: 0.8;
}

.left-rest-to-t-container {
  position: absolute !important;
  top: 0% !important;
  left: -6% !important;
  width: 100% !important;
}

.left-t {
  width: 63% !important;
  opacity: 0.8;
}

.left-rest-to-a-container {
  position: absolute !important;
  top: 19% !important;
  left: -8% !important;
  width: 100% !important;
}

.left-a {
  width: 57% !important;
  opacity: 0.8;
}

.left-rest-to-s-container {
  position: absolute !important;
  top: 13% !important;
  left: -9% !important;
  width: 100% !important;
}

.left-s {
  width: 61% !important;
  opacity: 0.8;
}

.left-rest-to-d-container {
  position: absolute !important;
  top: 20% !important;
  left: -7% !important;
  width: 100% !important;
}

.left-d {
  width: 57% !important;
  opacity: 0.8;
}

.left-rest-to-f-container {
  position: absolute !important;
  top: 14% !important;
  left: -8% !important;
  width: 100% !important;
}

.left-f {
  width: 59% !important;
  opacity: 0.8;
}

.left-rest-to-g-container {
  position: absolute !important;
  top: 13% !important;
  left: -5% !important;
  width: 100% !important;
}

.left-g {
  width: 60% !important;
  opacity: 0.8;
}

.left-rest-to-z-container {
  position: absolute !important;
  top: 8% !important;
  left: -7% !important;
  width: 100% !important;
}

.left-z {
  width: 60% !important;
  opacity: 0.8;
}

.left-rest-to-x-container {
    position: absolute !important;
    top: 14% !important;
    left: -4% !important;
    width: 100% !important;
}

.left-x {
  width: 60% !important;
  opacity: 0.8;
}

.left-rest-to-c-container {
  position: absolute !important;
  top: 20% !important;
  left: -2% !important;
  width: 100% !important;
}

.left-c {
  width: 57% !important;
  opacity: 0.8;
}

.left-rest-to-v-container {
  position: absolute !important;
  top: 16% !important;
  left: -10% !important;
  width: 100% !important;
}

.left-v {
  width: 58% !important;
  opacity: 0.8;
}

.left-rest-to-b-container {
  position: absolute !important;
  top: 14% !important;
  left: -2% !important;
  width: 100% !important;
}

.left-b {
  width: 60% !important;
  opacity: 0.8;
}

.left-rest-to-1-container, .left-rest-to-exclamation-container {
  position: absolute !important;
  top: -28% !important;
  left: -20% !important;
  width: 100% !important;
}

.left-1, .left-exclamation  {
  width: 70% !important;
  opacity: 0.6;
}

.left-rest-to-2-container, .left-rest-to-at-container {
  position: absolute !important;
  top: -25% !important;
  left: -15% !important;
  width: 100% !important;
}

.left-2, .left-at {
  width: 75% !important;
  opacity: 0.8;
}

.left-rest-to-3-container, .left-rest-to-hash-container {
  position: absolute !important;
  top: -24% !important;
  left: -18% !important;
  width: 100% !important;
}

.left-3, .left-hash  {
  width: 74% !important;
  opacity: 0.8;
}

.left-rest-to-4-container, .left-rest-to-dollar-container {
  position: absolute !important;
  top: -23% !important;
  left: -15% !important;
  width: 100% !important;
}

.left-4, .left-dollar {
  width: 74% !important;
  opacity: 0.8;
}

.left-rest-to-5-container, .left-rest-to-percentage-container {
  position: absolute !important;
  top: -28% !important;
  left: -15% !important;
  width: 100% !important;
}

.left-5, .left-percentage {
  width: 75% !important;
  opacity: 0.8;
}
@media only screen and (min-width: 320px) and (max-width: 639px) {

}