/* Shorthand Class */
* {
  padding: 0;
  margin: 0;
}

body {
  font-family: Nunito;
}

.flex {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-items: center;
}

/* Main Css */
#container {
  width: 100%;
  max-width: 1440px;
  margin: auto;
}

#header {
  display: flex;
  justify-content: space-between;
  margin: auto;
  padding: 25px 0;
  width: 80%;
}

.profile {
  width: 50px;
  border-radius: 50%;
  border: 4px solid #423f55;
}

.main-container {
  width: 80%;
  box-sizing: border-box;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
  gap: 35px;
  margin: auto;
}

.overview-container {
  width: 90%;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.container {
  padding: 2px 16px;
}

.chapter-card-p {
  line-height: 26px;
  margin: 10px 20px;
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
}

.chapter-card-p:hover {
  cursor: pointer;
  color: #ff0000;
}

.sample {
  position: fixed;
  width: 100vw;
}

.nest-card {
  border-radius: 16px;
  max-width: 175px;
  flex: 1;
  background: url("../../images/svg/nest-card-bg.svg");
  background-repeat: no-repeat;
}

.nest-image {
  width: 50%;
  padding-top: 15px;
}

.nest-card-font {
  font-family: Nunito;
  font-weight: 700;
  font-size: 30px;
  color: white;
  text-align: center;
  padding: 1px 0;
}

.nest-card-font span {
  font-size: 22px;
}

.batch-card {
  background: linear-gradient(100.9deg, #f8f0e0 67.38%, #ffffff 101.21%);
  border-radius: 16px;
  flex: 1;
  padding: 15px 25px;
}

.earned-star {
  padding: 12px;
  font-family: Nunito;
  color: #000000;
}

.earned-star-count {
  color: #2bb596;
  margin-left: 5px;
}

.next-batch-container {
  display: flex;
  justify-content: space-between;
  text-align: center;
  font-family: Source Sans Pro;
  font-weight: 700;
}

.next-batch-container img {
  width: 40px;
}

.next-batch-container p {
  flex: 1;
  align-self: center;
}

.progress-bar {
  background-color: #d6cdc7;
  border-radius: 25px;
}

.progress-loader {
  background-image: linear-gradient(to right, #6505b4, #cd1d9c);
  text-align: center;
  border-radius: 25px 0px 0px 25px;
}

.overall-status-card {
  background: #ffce22;
  border-radius: 16px;
  flex: 2;
  padding: 15px;
}

.overall-main-font {
  font-family: Nunito;
  font-weight: 600;
  font-size: 21px;
  color: #56428f;
  text-align: center;
  margin: 15px;
}

.overall-meta-icon {
  width: 34px;
  height: 34px;
  align-self: center;
  margin: 0 10px;
}

.meta-block {
  display: flex;
  justify-content: space-around;
  margin: 15px 0;
  float: left;
  width: 100%;
}

.meta-text-block {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.meta-text-block p {
  font-family: Nunito;
}

.meta-text-block p:first-child {
  font-size: 12px;
}

.meta-text-block p:last-child {
  font-size: 16px;
  font-weight: bold;
}

.chapter-title-block {
  display: flex;
  justify-content: space-between;
}

.chapter-meta-block {
  display: flex;
  gap: 30px;
}

.chapter-meta-text-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chapter-meta-text-block p {
  font-family: Nunito;
}

.chapter-meta-text-block p:first-child {
  font-size: 12px;
}

.chapter-meta-text-block p:last-child {
  font-size: 16px;
  font-weight: bold;
}

.chapters-container {
  width: 90%;
  margin: 0 50px;
}

.chapter-group-card {
  position: relative;
  background: linear-gradient(
    106.25deg,
    rgba(255, 255, 255, 0.48) 5.86%,
    rgba(255, 255, 255, 0.276) 85.48%
  );
  border: 0.5px solid rgba(255, 255, 255, 0.6);
  box-sizing: border-box;
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  border-radius: 32px;
  margin-bottom: 30px;
}

.level-tag {
  height: 50px;
  width: 200px;
  position: absolute;
  transform: rotate(-90deg);
  left: -126px;
  bottom: 35%;
  background: #f2f2f2;
  border-radius: 15px 15px 0 0;
  text-align: center;
}

.level-tag p {
  font-family: "Lato", sans-serif;
  font-size: 30px;
  font-weight: 700;
  margin: 5px;
}

.chapter-group-title {
  display: flex;
  margin: 20px 30px;
}

.chapter-group-title > p {
  font-family: Nunito;
  font-weight: bold;
  font-size: 35px;
  letter-spacing: 0.05em;
  color: #333333;
  flex: 1;
}

.chapter-container {
  display: flex;
  gap: 35px;
  flex-direction: column;
  border-top: 1px solid #ffffff;
  padding: 20px 40px 40px;
  -webkit-backdrop-filter: blur(32px);
  backdrop-filter: blur(32px);
}

.chapter-title {
  font-family: Nunito;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0.05em;
  color: #4f4f4f;
  margin: 20px 0;
}

.lesson-container {
  display: flex;
  justify-content: start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 25px;
}
.lesson-card {
  background: #c6fcff;
  backdrop-filter: blur(50px);
  border-radius: 15px 0;
  cursor: pointer;
  padding: 15px 15px;
  display: flex;
  flex-direction: column;
  flex: 0 0 135px;
  position: relative;
}

.star-rating {
  margin: 10px;
}

.star-rating img:first-child {
  margin: 0 10px;
}

.star-rating img:nth-child(2) {
  margin: 20px 10px;
}

.star-rating img:last-child {
  margin: 0 10px;
}

.lesson-card p.number {
  font-family: Roboto;
  font-weight: bold;
  font-size: 35px;
  line-height: 41px;
  letter-spacing: 0.05em;
  color: #333333;
  text-align: center;
}

.lesson-card p.title {
  font-family: Roboto;
  font-size: 18px;
  color: #000000;
  text-align: center;
  margin: 10px;
}

.lesson-status-container {
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 30px;
  width: 535px;
  top: 5%;
}

.top-bar {
  width: 80%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  align-items: center;
}

.meta-container {
  display: flex;
  width: 126px;
  gap: 10px;
  height: 35px;
}

.meta-font-container {
  width: 100px;
}

.meta-title {
  font-family: Nunito;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: #767676;
}

.meta-value {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #000000;
  margin: 0;
}

.greeting-container {
  height: 50px;
  vertical-align: middle;
  padding: 8px;
  border-radius: 8px;
  font-family: Nunito;
  font-weight: 600;
  font-size: 24px;
  color: #56428f;
  margin-right: 25px;
}

@media screen and (max-height: 750px) {
  .top-bar {
    padding: 10px 0px;
  }
}

/* Login Pages */

.login-background {
  background: url("../../images/photos/login-page-bg.png");
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: bottom;
  height: 100vh;
}

@media screen and (min-height: 0px) and (max-height: 800px) {
  .login-background {
    height: 100% !important;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1580px) {
  .marquee-btn {
    width: 16% !important;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1280px) {
  .marquee-btn {
    width: 17% !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .main-container {
    width: 95%;
    margin: 0 auto;
  }
  .chapter-title-block {
    flex-direction: column;
  }
  .lesson-container {
    gap: 11px;
  }
  .lesson-bg {
    background-size: 100% 100% !important;
  }
  .characters-container {
    flex-wrap: nowrap !important;
  }
  .marquee-btn {
    width: 25% !important;
  }
  .login-inner-container {
    flex-direction: column;
  }
  .login-description {
    width: 100% !important;
  }
  .login-form-container {
    width: 90% !important;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 580px) and (max-width: 766px) {
  .marquee-btn {
    width: 33% !important;
    font-size: 14px;
  }  
}

@media only screen and (min-width: 320px) and (max-width: 639px) {
  .main-container {
    width: 90%;
    margin: 0 auto;
  }
  .overview-container {
    flex-direction: column;
  }
  .chapters-container {
    margin: 0 auto;
  }
  .lesson-container {
    justify-content: center;
    align-items: center;
  }
  .chapter-title-block {
    flex-direction: column;
  }
  .chapter-meta-block {
    flex-direction: column;
  }
  .marquee-btn {
    width: 54% !important;
    font-size: 14px;
  }
}