@import url(https://fonts.googleapis.com/css2?family=Nunito&family=Source+Sans+Pro&family=Lato&family=Roboto);
progress {
  text-align: center;
  height: 1.5em;
  width: 100%;
  -webkit-appearance: none;
  border: none;
  position: relative;
}
progress:before {
  content: attr(data-label);
  vertical-align: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 4px;
  font-size: 12px;
  font-family: "Nunito";
  font-weight: 300;
}
progress::-moz-progress-bar {
  content: attr(data-label);
  background: #8dc63f;
  text-align: center;
  font-size: 12px;
  padding: 5px;
  border-radius: 3px 0px 0px 3px;
}

progress::-webkit-progress-value {
  background: #8dc63f;
  text-align: center;
  font-size: 12px;
  padding: 5px;
  border-radius: 3px 0px 0px 3px;
}

progress::-webkit-progress-bar {
  background-color: #cfcfcf;
  border-radius: 3px;
}

.group-rectangle {
  padding: 50px 30px;
  background: rgba(161, 92, 92, 0.1);
  background-image: url(/static/media/backgroungImage.be392f6b.svg);
  border-top: 1px solid rgba(255, 255, 255, 0.6);
  border-left: 1px solid rgba(255, 255, 255, 0.6);
  box-sizing: border-box;
  backdrop-filter: blur(20px);
  border-radius: 32px;
  background-attachment: fixed;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.15);
  width: 80%;
  box-sizing: border-box;
  -webkit-backdrop-filter: blur(20px);
  margin: auto;
  background: linear-gradient(
    106.25deg,
    rgba(255, 255, 255, 0.48) 5.86%,
    rgba(255, 255, 255, 0.276) 85.48%
  );
}

.profile-icon {
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  border-radius: 50%;
  height: 40px;
  min-width: 40px;
  padding: 3px;
  text-align: center;
}

.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-right: 18px;
  align-items: flex-end;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: -5px;
  background-color: #f9f9f9;
  min-width: 180px;
  box-shadow: 4px 4px 27px rgba(0, 0, 0, 0.15);
  z-index: 1;
  top: 50px;
  border-radius: 5px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.progress {
  background-color: #cfcfcf;
  border-radius: 3px;
  font-size: 12px;
  font-family: "Nunito";
  font-weight: 300;
}

.progress-inloader {
  background-color: #8dc63f;
  text-align: center;
}

.rectangle {
  margin-left: 130px;
  width: 10px;
  height: 10px;
  background-color: #f9f9f9;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-top: -5px;
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .group-rectangle {
    width: 90%;
  }
  .TabSize {
    width: 23vw !important;
    padding: 0 !important;
  }
  .TabActiveColor {
    width: 10rem !important;
  }
  .AchievementData {
    grid-gap: 27px !important;
    gap: 27px !important;
  }
}
/* Shorthand Class */
* {
  padding: 0;
  margin: 0;
}

body {
  font-family: Nunito;
}

.flex {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-items: center;
}

/* Main Css */
#container {
  width: 100%;
  max-width: 1440px;
  margin: auto;
}

#header {
  display: flex;
  justify-content: space-between;
  margin: auto;
  padding: 25px 0;
  width: 80%;
}

.profile {
  width: 50px;
  border-radius: 50%;
  border: 4px solid #423f55;
}

.main-container {
  width: 80%;
  box-sizing: border-box;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  grid-gap: 50px;
  grid-gap: 35px;
  gap: 35px;
  margin: auto;
}

.overview-container {
  width: 90%;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  grid-gap: 30px;
  gap: 30px;
}

.container {
  padding: 2px 16px;
}

.chapter-card-p {
  line-height: 26px;
  margin: 10px 20px;
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
}

.chapter-card-p:hover {
  cursor: pointer;
  color: #ff0000;
}

.sample {
  position: fixed;
  width: 100vw;
}

.nest-card {
  border-radius: 16px;
  max-width: 175px;
  flex: 1 1;
  background: url(/static/media/nest-card-bg.650b285c.svg);
  background-repeat: no-repeat;
}

.nest-image {
  width: 50%;
  padding-top: 15px;
}

.nest-card-font {
  font-family: Nunito;
  font-weight: 700;
  font-size: 30px;
  color: white;
  text-align: center;
  padding: 1px 0;
}

.nest-card-font span {
  font-size: 22px;
}

.batch-card {
  background: linear-gradient(100.9deg, #f8f0e0 67.38%, #ffffff 101.21%);
  border-radius: 16px;
  flex: 1 1;
  padding: 15px 25px;
}

.earned-star {
  padding: 12px;
  font-family: Nunito;
  color: #000000;
}

.earned-star-count {
  color: #2bb596;
  margin-left: 5px;
}

.next-batch-container {
  display: flex;
  justify-content: space-between;
  text-align: center;
  font-family: Source Sans Pro;
  font-weight: 700;
}

.next-batch-container img {
  width: 40px;
}

.next-batch-container p {
  flex: 1 1;
  align-self: center;
}

.progress-bar {
  background-color: #d6cdc7;
  border-radius: 25px;
}

.progress-loader {
  background-image: linear-gradient(to right, #6505b4, #cd1d9c);
  text-align: center;
  border-radius: 25px 0px 0px 25px;
}

.overall-status-card {
  background: #ffce22;
  border-radius: 16px;
  flex: 2 1;
  padding: 15px;
}

.overall-main-font {
  font-family: Nunito;
  font-weight: 600;
  font-size: 21px;
  color: #56428f;
  text-align: center;
  margin: 15px;
}

.overall-meta-icon {
  width: 34px;
  height: 34px;
  align-self: center;
  margin: 0 10px;
}

.meta-block {
  display: flex;
  justify-content: space-around;
  margin: 15px 0;
  float: left;
  width: 100%;
}

.meta-text-block {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.meta-text-block p {
  font-family: Nunito;
}

.meta-text-block p:first-child {
  font-size: 12px;
}

.meta-text-block p:last-child {
  font-size: 16px;
  font-weight: bold;
}

.chapter-title-block {
  display: flex;
  justify-content: space-between;
}

.chapter-meta-block {
  display: flex;
  grid-gap: 30px;
  gap: 30px;
}

.chapter-meta-text-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chapter-meta-text-block p {
  font-family: Nunito;
}

.chapter-meta-text-block p:first-child {
  font-size: 12px;
}

.chapter-meta-text-block p:last-child {
  font-size: 16px;
  font-weight: bold;
}

.chapters-container {
  width: 90%;
  margin: 0 50px;
}

.chapter-group-card {
  position: relative;
  background: linear-gradient(
    106.25deg,
    rgba(255, 255, 255, 0.48) 5.86%,
    rgba(255, 255, 255, 0.276) 85.48%
  );
  border: 0.5px solid rgba(255, 255, 255, 0.6);
  box-sizing: border-box;
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  border-radius: 32px;
  margin-bottom: 30px;
}

.level-tag {
  height: 50px;
  width: 200px;
  position: absolute;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  left: -126px;
  bottom: 35%;
  background: #f2f2f2;
  border-radius: 15px 15px 0 0;
  text-align: center;
}

.level-tag p {
  font-family: "Lato", sans-serif;
  font-size: 30px;
  font-weight: 700;
  margin: 5px;
}

.chapter-group-title {
  display: flex;
  margin: 20px 30px;
}

.chapter-group-title > p {
  font-family: Nunito;
  font-weight: bold;
  font-size: 35px;
  letter-spacing: 0.05em;
  color: #333333;
  flex: 1 1;
}

.chapter-container {
  display: flex;
  grid-gap: 35px;
  gap: 35px;
  flex-direction: column;
  border-top: 1px solid #ffffff;
  padding: 20px 40px 40px;
  -webkit-backdrop-filter: blur(32px);
  backdrop-filter: blur(32px);
}

.chapter-title {
  font-family: Nunito;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0.05em;
  color: #4f4f4f;
  margin: 20px 0;
}

.lesson-container {
  display: flex;
  justify-content: start;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 25px;
  gap: 25px;
}
.lesson-card {
  background: #c6fcff;
  -webkit-backdrop-filter: blur(50px);
          backdrop-filter: blur(50px);
  border-radius: 15px 0;
  cursor: pointer;
  padding: 15px 15px;
  display: flex;
  flex-direction: column;
  flex: 0 0 135px;
  position: relative;
}

.star-rating {
  margin: 10px;
}

.star-rating img:first-child {
  margin: 0 10px;
}

.star-rating img:nth-child(2) {
  margin: 20px 10px;
}

.star-rating img:last-child {
  margin: 0 10px;
}

.lesson-card p.number {
  font-family: Roboto;
  font-weight: bold;
  font-size: 35px;
  line-height: 41px;
  letter-spacing: 0.05em;
  color: #333333;
  text-align: center;
}

.lesson-card p.title {
  font-family: Roboto;
  font-size: 18px;
  color: #000000;
  text-align: center;
  margin: 10px;
}

.lesson-status-container {
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 30px;
  width: 535px;
  top: 5%;
}

.top-bar {
  width: 80%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  align-items: center;
}

.meta-container {
  display: flex;
  width: 126px;
  grid-gap: 10px;
  gap: 10px;
  height: 35px;
}

.meta-font-container {
  width: 100px;
}

.meta-title {
  font-family: Nunito;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: #767676;
}

.meta-value {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #000000;
  margin: 0;
}

.greeting-container {
  height: 50px;
  vertical-align: middle;
  padding: 8px;
  border-radius: 8px;
  font-family: Nunito;
  font-weight: 600;
  font-size: 24px;
  color: #56428f;
  margin-right: 25px;
}

@media screen and (max-height: 750px) {
  .top-bar {
    padding: 10px 0px;
  }
}

/* Login Pages */

.login-background {
  background: url(/static/media/login-page-bg.b7f80256.png);
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: bottom;
  height: 100vh;
}

@media screen and (min-height: 0px) and (max-height: 800px) {
  .login-background {
    height: 100% !important;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1580px) {
  .marquee-btn {
    width: 16% !important;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1280px) {
  .marquee-btn {
    width: 17% !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .main-container {
    width: 95%;
    margin: 0 auto;
  }
  .chapter-title-block {
    flex-direction: column;
  }
  .lesson-container {
    grid-gap: 11px;
    gap: 11px;
  }
  .lesson-bg {
    background-size: 100% 100% !important;
  }
  .characters-container {
    flex-wrap: nowrap !important;
  }
  .marquee-btn {
    width: 25% !important;
  }
  .login-inner-container {
    flex-direction: column;
  }
  .login-description {
    width: 100% !important;
  }
  .login-form-container {
    width: 90% !important;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 580px) and (max-width: 766px) {
  .marquee-btn {
    width: 33% !important;
    font-size: 14px;
  }  
}

@media only screen and (min-width: 320px) and (max-width: 639px) {
  .main-container {
    width: 90%;
    margin: 0 auto;
  }
  .overview-container {
    flex-direction: column;
  }
  .chapters-container {
    margin: 0 auto;
  }
  .lesson-container {
    justify-content: center;
    align-items: center;
  }
  .chapter-title-block {
    flex-direction: column;
  }
  .chapter-meta-block {
    flex-direction: column;
  }
  .marquee-btn {
    width: 54% !important;
    font-size: 14px;
  }
}
* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.backimage {
  background-image: url(/static/media/Acc_bg.04164ad4.png);
  margin: 0;
  background-attachment: fixed;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: auto;
}

.home-bg {
  background-image: url("https://docs-assets.katomaran.tech/images/typing/lesson/2022/02/Frame_3.png");
  background-attachment: fixed;
  z-index: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.lesson-bg {
  background-attachment: fixed;
  z-index: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
}

:root {
  --speed_percentage: 0turn;
  --accuarcy_percentage: 0turn;
  --typos_percentage: 0turn;
}
.lesson-page-header {
  margin: auto;
  width: 60%;
}
.text-content-container {
  width: 80%;
  margin: auto;
  margin-bottom: 4%;
  padding: 40px 40px 90px;
  border-radius: 32px;
  background: linear-gradient(
    106.25deg,
    rgba(255, 255, 255, 0.48) 5.86%,
    rgba(255, 255, 255, 0.276) 85.48%
  );
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
}
.lesson-page-header-blur {
  -webkit-filter: blur(12px);
          filter: blur(12px);
}
.text-stats-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.lesson-name-block {
  font-family: Nunito;
  font-weight: 700;
  font-size: 20px;
  font-weight: bold;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ff0000;
}
.stats-container {
  display: flex;
  grid-gap: 30px;
  gap: 30px;
  font-family: Nunito;
}
.lesson-typos,
.lesson-wpm {
  margin: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 27.28px;
}
.lesson-typos > span,
.lesson-wpm > span {
  margin: 0;
  font-weight: 700;
  line-height: 34.1px;
  margin-left: 10px;
}
.text-content-inner-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}
.characters-container {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.character-green {
  border-bottom: 2px solid #639d35;
  border-radius: 0;
  background-color: #cbe8b5;
}
.character-red {
  border-bottom: 2px solid #c61837;
  background-color: #f4c3c3;
}
.character {
  border-radius: 4px;
  font-family: Nunito;
  font-size: 45px;
  font-weight: 600;
  width: 75px;
  text-align: center;
}
.words-container {
  font-family: Nunito;
  font-size: 28px;
  border-radius: 4px;
  letter-spacing: 2px;
  word-spacing: 6px;
  line-height: 45px;
  overflow: hidden;
  height: 225px;
}
.words-container p {
  transition: margin 1000ms ease-in-out;
}
.word-green {
  background-color: lightgreen;
}
.word-red {
  background-color: lightsalmon;
}
.word-blue {
  border-bottom: 2px solid blue;
}
.keyboard-container {
  width: 700px;
  margin: auto;
  position: relative;
}

.para-container {
  display: block;
  border-radius: 4px;
  font-family: Nunito;
  font-size: 45px;
  font-weight: 600;
  text-align: left;
}
.hg-rows {
  padding: 2%;
}
.text-correct-bg {
  color: #68be62;
}
.text-wrong-bg {
  color: #ff0000;
}
.focus-next-letter {
  color: #2788ce;
}
.popupCard {
  font-weight: bold;
  border: 3px solid #f1f1f1;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 2;
  max-width: 65vw;
  max-height: 54vh;
  text-align: center;
}
.popupbackground {
  margin: 0;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: auto;
  background-color: #2E384E;
}
.successStar {
  margin: 35px;
  width: 94px;
  box-shadow: 1px 200px 25px -106px rgba(202,83,83,0.69);
}
.group {
  padding: 13px 0px 0px 0px;
  box-sizing: border-box;
  position: relative;
}
.success-refresh-btn {
  text-align: center;
  padding: 5px 20px;
  cursor: pointer;
  position: absolute;
  right: 50px;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  font-size: 22px;
  font-weight: 600;
}
.success-star-container{
  text-align: center;
  min-height: 300px;
  padding-top: 30px;
}
.group2 { 
  box-sizing: border-box;
  width: 100%;
  z-index: 1;
}
.progress-whole-container {
  display: flex;
  justify-content: space-around;
  text-align: center;
  font-weight: 800;
  font-family: Nunito;
  font-size: 16px;
  width: 65%;
  margin: 0 auto;
}
.progress-container {
  width: 100%;
  max-width: 200px;
}
.progress-inner-container {
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  background: #56627A;
  position: relative;
  border-top-left-radius: 100% 200%;
  border-top-right-radius: 100% 200%;
  overflow: hidden;
}
.progress-fill {
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: 100%;
  -webkit-transform-origin: center top;
          transform-origin: center top;
}
.progress-fill-speed{
  -webkit-transform: rotate(0turn);
          transform: rotate(0turn);
  -webkit-animation: speed_animated 0.9s cubic-bezier(0.9,0.5,0.5,1) forwards;
          animation: speed_animated 0.9s cubic-bezier(0.9,0.5,0.5,1) forwards;
}
.progress-fill-accuarcy{
  -webkit-transform: rotate(0turn);
          transform: rotate(0turn);
  -webkit-animation: accuarcy_animated 0.9s cubic-bezier(0.9,0.5,0.5,1) forwards;
          animation: accuarcy_animated 0.9s cubic-bezier(0.9,0.5,0.5,1) forwards;
}
.progress-fill-typos{
  -webkit-transform: rotate(0turn);
          transform: rotate(0turn);
  -webkit-animation: typos_animated 0.9s cubic-bezier(0.9,0.5,0.5,1) forwards;
          animation: typos_animated 0.9s cubic-bezier(0.9,0.5,0.5,1) forwards;
}
@-webkit-keyframes speed_animated {
  100% {
    -webkit-transform: rotate(0turn);
            transform: rotate(0turn);
    -webkit-transform: rotate(var(--speed_percentage));
            transform: rotate(var(--speed_percentage));
  }
}
@keyframes speed_animated {
  100% {
    -webkit-transform: rotate(0turn);
            transform: rotate(0turn);
    -webkit-transform: rotate(var(--speed_percentage));
            transform: rotate(var(--speed_percentage));
  }
}
@-webkit-keyframes accuarcy_animated {
  100% {
    -webkit-transform: rotate(0turn);
            transform: rotate(0turn);
    -webkit-transform: rotate(var(--accuarcy_percentage));
            transform: rotate(var(--accuarcy_percentage));
  }
}
@keyframes accuarcy_animated {
  100% {
    -webkit-transform: rotate(0turn);
            transform: rotate(0turn);
    -webkit-transform: rotate(var(--accuarcy_percentage));
            transform: rotate(var(--accuarcy_percentage));
  }
}
@-webkit-keyframes typos_animated {
  100% {
    -webkit-transform: rotate(0turn);
            transform: rotate(0turn);
    -webkit-transform: rotate(var(--typos_percentage));
            transform: rotate(var(--typos_percentage));
  }
}
@keyframes typos_animated {
  100% {
    -webkit-transform: rotate(0turn);
            transform: rotate(0turn);
    -webkit-transform: rotate(var(--typos_percentage));
            transform: rotate(var(--typos_percentage));
  }
}
.progress-cover {
  width: 80%;
  height: 150%;
  background-color: #2E384E;
  border-radius: 50%;
  position: absolute;
  top: 20%;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}
.progress-des {
  font-size: 24px;
  font-weight: 700;
}
.bar {
  width: 65%;
  margin: 0 auto;
  padding-top: 150px;
}
.progress-line {
  width: 100%;
  height: 13px;
  background-color: #56627A;
  position: relative;
  border-radius: 50px;
}
@-webkit-keyframes animate {
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}
@keyframes animate {
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}
.progress-line span {
  height: 100%;
  width: 80%;
  position: absolute;
  background: linear-gradient(to left, #E1F23D, #B1B844);
  border-radius: 50px;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: left;
          transform-origin: left;
  -webkit-animation: animate 0.5s cubic-bezier(1,0,0.5,1) forwards;
          animation: animate 0.5s cubic-bezier(1,0,0.5,1) forwards;
}
.progress-line span::before {
  content: '';
  position: absolute;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFASURBVHgBfVFBTsJQEP3vF6rLujcE1hIDe5N+TkI9AXICwgmEEwA3wBu08QAQlTUm4lrWpfzxjbYJC3GSaf+feX/mvRmY0lZbF12GfiACJ8a4MrwW76ftxvO8wtkS3AzrsjoSTB/nB1zdXGfwgqGxcJvPeKsYxUI/m128ZXJRFGYeQGYMdegRfckCw1rNJNZKP8/RxdvOJYDEvIwJXpXAU9vzUfeiLrOjSEZKMvAeC4If/wCrRdpVqQZADNIR5fvyHos5b9qlRZ1flWitvP/nQdRtpT95S9UpRXX4X59Di8jT68edU4xVIeQ4KgT3Z7rsvbEP1gZ9ap3aorATBptcWl+nwfOyAtJTjWmO1V27kc6tcsMBPW44CUMZIThOb5sZVCTPYx2nGCTI0dMqOO2tO9Ex43dxhmNLWSjjQieV6G+27KAcI/Iy2AAAAABJRU5ErkJggg==);
  right: -5px;
  top: -56px;
  width: 16px;
  height: 16px;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.progress-line span::after {
  content: '';
  position: absolute;
  border-left: 4px solid white;
  right: 1px;
  top: -41px;
  height: 35px;
  border-radius: 10px;
}
.progress-content {
  position: absolute;
  top: -59px;
  left: 80%;
  padding-left: 15px;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: left;
          transform-origin: left;
  -webkit-animation: animate 0.5s 0.5s cubic-bezier(1,0,0.5,1) forwards;
          animation: animate 0.5s 0.5s cubic-bezier(1,0,0.5,1) forwards;
}
.progress-content-title {
  font-size: 16px;
  font-weight: 400;
}
.progress-content-desc {
  font-size: 18px;
  font-weight: 600;
}
.successpage-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  grid-gap: 100px;
  gap: 100px;
}
.lesson-success-btn{
  width: 12rem;
  font-size: 28px;
  font-family: Source Sans Pro;
  font-weight: 700;
  height: 4rem;
  color: #fff;
  border-radius: 40px;
  border: 1px solid #fff;
  background-color: #2E384E;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}
.lesson-success-btn > span:hover {
  text-decoration: none !important;
}
.lesson-success-btn:hover {
  color: #5E812B;
  background-color: #fff;
  text-decoration: none;
  border: 1px solid #fff;
  cursor: pointer;
  text-decoration: none;
}
.lesson-next-btn {
  width: 12rem;
  background: #fff;
  font-size: 28px;
  color: #5E812B;
  border: 1px solid #fff;
  font-family: Source Sans Pro;
  font-weight: 700;
  height: 4rem;
  border-radius: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}
.next-icon {
  display: flex;
}
.next-icon > svg {
  stroke: #5E812B !important;
}
.lesson-next-btn:hover {
  color: #fff;
  background-color: #2E384E;
  text-decoration: none;
  border: 1px solid #fff;
  cursor: pointer;
}
.lesson-next-btn:hover .next-icon > svg {
  stroke: #fff !important;
}
#firstStar {
  margin: 0px 0px -35px 0px;
}
#lastStar {
  margin: 0px 0px -35px 0px;
}
.home:link {
  text-decoration: none;
}
.home:hover {
  text-decoration: none;
  color: #15646f;
}
.home {
  color: #15646f !important;
}

/* Blinking Effect */
.character-blue {
  border-bottom: 2px solid blue;
  -webkit-animation: 0.75s blink step-end infinite;
  animation: 0.75s blink step-end infinite;
}

@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: blue;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: blue;
  }
}

@media screen and (max-width: 1400px) {
  .word-font {
    font-size: 27px;
  }
  .text-content-container {
    width: 85%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .word-font {
    font-size: 27px;
  }
  .text-content-container {
    width: 90%;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
  .word-font {
    font-size: 24px;
  }
  .text-content-container {
    width: 90%;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1100px) {
  .word-font {
    font-size: 24px;
  }
  .text-content-container {
    width: 95%;
  }
}

@media screen and (max-width: 1035px) {
  .word-font {
    font-size: 21px;
  }
  .text-content-container {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .word-font {
    font-size: 18px;
  }
  .text-content-container {
    width: 100%;
  }
}

@media screen and (max-height: 900px) {
  .words-container {
    height: 180px;
  }
}

@media screen and (max-height: 750px) {
  .words-container {
    height: 135px;
  }

  .text-content-container {
    margin-bottom: 1%;
    padding: 40px 40px 45px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .group2 {
    width: 80% !important;
  }
  .successpage-btn {
    padding: 50px 0px !important;
  }
  .test-content .word-font {
    font-size: 48%;
  }
  .test-form-container {
    width: 90% !important;
  }
  .test-stats-title {
    font-size: 25px !important;
    line-height: 30px !important;
  }
  .test-stats-value {
    font-size: 20px !important;
  }
  .test-icon-container {
    display: flex;
    align-items: center;
  }
  .test-success-center-card {
    flex-direction: column;
  }
  .success-refresh-btn {
    right: -45px;
  }
  .progress-whole-container {
    width: 90%;
    grid-gap: 10px;
    gap: 10px;
  }
  .bar {
    width: 98%;
  }
}

@media screen and (min-width: 320px) and (max-width: 639px) {
  .text-content-container {
    width: 100%;
  }
  .character {
    font-size: 31px;
  }
  .text-stats-container {
    flex-direction: column;
  }
  .characters-container {
    flex-wrap: nowrap;
    overflow: hidden;
    grid-gap: 15px;
    gap: 15px;
  }
  .keyboard-container {
    width: 402px;
  }
  .group2 {
    width: 95%;
  }
  .progress-whole-container {
    flex-direction: column;
    align-items: center;
    grid-gap: 50px;
    gap: 50px;
  }
  .successpage-btn {
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
  }
  .success-refresh-btn {
    right: 30%;
    bottom: 30px;    
  }
}
.left-rest-to-rest-container {
  position: absolute !important;
  top: 23% !important;
  left: -7% !important;
  width: 100% !important;
}

.left-rest {
  width: 60% !important;
  opacity: 0.8;
}

.left-rest-to-q-container {
  position: absolute !important;
  top: 0% !important;
  left: -11.5% !important;
  width: 100% !important;
}

.left-q {
  width: 63% !important;
  opacity: 0.8;
}

.left-rest-to-w-container {
  position: absolute !important;
  top: 6% !important;
  left: -9% !important;
  width: 100% !important;
}

.left-w {
  width: 61% !important;
  opacity: 0.8;
}

.left-rest-to-e-container {
  position: absolute !important;
  top: 5% !important;
  left: -9% !important;
  width: 100% !important;
}

.left-e {
  width: 62% !important;
  opacity: 0.8;
}

.left-rest-to-r-container {
  position: absolute !important;
  top: 11% !important;
  left: -8% !important;
  width: 100% !important;
}

.left-r {
  width: 58% !important;
  opacity: 0.8;
}

.left-rest-to-t-container {
  position: absolute !important;
  top: 0% !important;
  left: -6% !important;
  width: 100% !important;
}

.left-t {
  width: 63% !important;
  opacity: 0.8;
}

.left-rest-to-a-container {
  position: absolute !important;
  top: 19% !important;
  left: -8% !important;
  width: 100% !important;
}

.left-a {
  width: 57% !important;
  opacity: 0.8;
}

.left-rest-to-s-container {
  position: absolute !important;
  top: 13% !important;
  left: -9% !important;
  width: 100% !important;
}

.left-s {
  width: 61% !important;
  opacity: 0.8;
}

.left-rest-to-d-container {
  position: absolute !important;
  top: 20% !important;
  left: -7% !important;
  width: 100% !important;
}

.left-d {
  width: 57% !important;
  opacity: 0.8;
}

.left-rest-to-f-container {
  position: absolute !important;
  top: 14% !important;
  left: -8% !important;
  width: 100% !important;
}

.left-f {
  width: 59% !important;
  opacity: 0.8;
}

.left-rest-to-g-container {
  position: absolute !important;
  top: 13% !important;
  left: -5% !important;
  width: 100% !important;
}

.left-g {
  width: 60% !important;
  opacity: 0.8;
}

.left-rest-to-z-container {
  position: absolute !important;
  top: 8% !important;
  left: -7% !important;
  width: 100% !important;
}

.left-z {
  width: 60% !important;
  opacity: 0.8;
}

.left-rest-to-x-container {
    position: absolute !important;
    top: 14% !important;
    left: -4% !important;
    width: 100% !important;
}

.left-x {
  width: 60% !important;
  opacity: 0.8;
}

.left-rest-to-c-container {
  position: absolute !important;
  top: 20% !important;
  left: -2% !important;
  width: 100% !important;
}

.left-c {
  width: 57% !important;
  opacity: 0.8;
}

.left-rest-to-v-container {
  position: absolute !important;
  top: 16% !important;
  left: -10% !important;
  width: 100% !important;
}

.left-v {
  width: 58% !important;
  opacity: 0.8;
}

.left-rest-to-b-container {
  position: absolute !important;
  top: 14% !important;
  left: -2% !important;
  width: 100% !important;
}

.left-b {
  width: 60% !important;
  opacity: 0.8;
}

.left-rest-to-1-container, .left-rest-to-exclamation-container {
  position: absolute !important;
  top: -28% !important;
  left: -20% !important;
  width: 100% !important;
}

.left-1, .left-exclamation  {
  width: 70% !important;
  opacity: 0.6;
}

.left-rest-to-2-container, .left-rest-to-at-container {
  position: absolute !important;
  top: -25% !important;
  left: -15% !important;
  width: 100% !important;
}

.left-2, .left-at {
  width: 75% !important;
  opacity: 0.8;
}

.left-rest-to-3-container, .left-rest-to-hash-container {
  position: absolute !important;
  top: -24% !important;
  left: -18% !important;
  width: 100% !important;
}

.left-3, .left-hash  {
  width: 74% !important;
  opacity: 0.8;
}

.left-rest-to-4-container, .left-rest-to-dollar-container {
  position: absolute !important;
  top: -23% !important;
  left: -15% !important;
  width: 100% !important;
}

.left-4, .left-dollar {
  width: 74% !important;
  opacity: 0.8;
}

.left-rest-to-5-container, .left-rest-to-percentage-container {
  position: absolute !important;
  top: -28% !important;
  left: -15% !important;
  width: 100% !important;
}

.left-5, .left-percentage {
  width: 75% !important;
  opacity: 0.8;
}
@media only screen and (min-width: 320px) and (max-width: 639px) {

}
.right-rest-to-rest-container {
  position: absolute !important;
  top: 14% !important;
  left: 40% !important;
  width: 100% !important;
}

.right-rest {
  width: 60% !important;
  opacity: 0.8;
}

.right-rest-to-space-container {
  position: absolute !important;
  top: 13% !important;
  left: 38% !important;
  width: 100% !important;
}

.right-space {
  width: 61% !important;
  opacity: 0.8;
}

.right-rest-to-y-container {
  position: absolute !important;
  top: -9% !important;
  left: 31% !important;
  width: 100% !important;
}

.right-y {
  width: 62% !important;
  opacity: 0.8;
}

.right-rest-to-u-container {
  position: absolute !important;
  top: 4% !important;
  left: 35% !important;
  width: 100% !important;
}

.right-u {
  width: 62% !important;
  opacity: 0.8;
}

.right-rest-to-i-container {
  position: absolute !important;
  top: 4% !important;
  left: 37% !important;
  width: 100% !important;
}

.right-i {
  width: 65% !important;
  opacity: 0.8;
}

.right-rest-to-o-container {
  position: absolute !important;
  top: 7% !important;
  left: 39% !important;
  width: 100% !important;
}

.right-o {
  width: 64% !important;
  opacity: 0.8;
}

.right-rest-to-p-container {
  position: absolute !important;
  top: 3% !important;
  left: 41% !important;
  width: 100% !important;
}

.right-p {
  width: 65% !important;
  opacity: 0.8;
}

.right-rest-to-h-container {
  position: absolute !important;
  top: 12% !important;
  left: 36% !important;
  width: 100% !important;
}

.right-h {
  width: 63% !important;
  opacity: 0.8;
}

.right-rest-to-j-container {
  position: absolute !important;
  top: 14% !important;
  left: 37% !important;
  width: 100% !important;
}

.right-j {
  width: 63% !important;
  opacity: 0.8;
}

.right-rest-to-k-container {
  position: absolute !important;
  top: 10% !important;
  left: 37% !important;
  width: 100% !important;
}

.right-k {
  width: 65% !important;
  opacity: 0.8;
}

.right-rest-to-l-container {
  position: absolute !important;
  top: 12% !important;
  left: 31% !important;
  width: 100% !important;
}

.right-l {
  width: 64% !important;
  opacity: 0.8;
}

.right-rest-to-semicolon-container {
  position: absolute !important;
  top: 10% !important;
  left: 38% !important;
  width: 100% !important;
}

.right-semicolon {
  width: 64% !important;
  opacity: 0.8;
}

.right-rest-to-n-container {
  position: absolute !important;
  top: 8% !important;
  left: 40% !important;
  width: 100% !important;
}

.right-n {
  width: 65% !important;
  opacity: 0.8;
}

.right-rest-to-m-container {
  position: absolute !important;
  top: 9% !important;
  left: 43% !important;
  width: 100% !important;
}

.right-m {
  width: 70% !important;
  opacity: 0.8;
}

.right-rest-to-comma-container {
  position: absolute !important;
  top: -17% !important;
  left: 34% !important;
  width: 100% !important;
}

.right-comma {
  width: 72% !important;
  opacity: 0.8;
}

.right-rest-to-dot-container {
  position: absolute !important;
  top: -14% !important;
  left: 35% !important;
  width: 100% !important;
}

.right-dot {
  width: 69% !important;
  opacity: 0.8;
}

.right-rest-to-slash-container {
  position: absolute !important;
  top: -10% !important;
  left: 34% !important;
  width: 100% !important;
}

.right-slash {
  width: 68% !important;
  opacity: 0.8;
}

.right-rest-to-6-container, .right-rest-to-caret-container {
  position: absolute !important;
  top: -25% !important;
  left: 22% !important;
  width: 100% !important;
}

.right-6, .right-caret {
  width: 68% !important;
  opacity: 0.8;
}

.right-rest-to-7-container, .right-rest-to-ampersand-container {
  position: absolute !important;
  top: -19% !important;
  left: 27% !important;
  width: 100% !important;
}

.right-7, .right-ampersand {
  width: 60% !important;
  opacity: 0.8;
}

.right-rest-to-8-container, .right-rest-to-asterisk-container {
  position: absolute !important;
  top: -24% !important;
  left: 29% !important;
  width: 100% !important;
}

.right-8, .right-asterisk {
  width: 60% !important;
  opacity: 0.9;
}

.right-rest-to-9-container, .right-rest-to-open-container {
  position: absolute !important;
  top: -19% !important;
  left: 26% !important;
  width: 100% !important;
}

.right-9, .right-open {
  width: 68% !important;
  opacity: 0.9;
}

.right-rest-to-0-container, .right-rest-to-close-container {
  position: absolute !important;
  top: -25% !important;
  left: 28% !important;
  width: 100% !important;
}

.right-0, .right-close {
  width: 70% !important;
  opacity: 0.8;
}
@media only screen and (min-width: 320px) and (max-width: 640px) {
 
}
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  -webkit-transform-origin: 40px 40px;
          transform-origin: 40px 40px;
  -webkit-animation: lds-spinner 1.2s linear infinite;
          animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: rgb(122, 3, 3);
  left: 50%;
  top: 50%;
  z-index: 1;
}
.lds-spinner div:nth-child(1) {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
@-webkit-keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.my-root {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.root-container {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
}

div.root-container {
  padding: 20px;
}

.background-image {
  background-image: url("https://docs-assets.katomaran.tech/images/typing/lesson/2022/02/Frame_3.png");
  margin: 0;
  background-attachment: fixed;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: auto;
}
.main-card {
  padding: 50px 30px;
  border-radius: 32px;
  background-attachment: fixed;
  width: 80%;
  box-sizing: border-box;
  margin: auto;
}
.inner-card {
  -webkit-backdrop-filter: blur(45px);
          backdrop-filter: blur(45px);
  border: 1.5px solid #ffffff;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 15px 0px 15px 15px;
}
.right-card {
  -webkit-backdrop-filter: blur(45px);
          backdrop-filter: blur(45px);
  border: 1.5px solid #ffffff;
  box-sizing: border-box;
  /* background: rgba(255, 255, 255, 0.25); */
  background: linear-gradient(
    106.25deg,
    rgba(255, 255, 255, 0.48) 5.86%,
    rgba(255, 255, 255, 0.276) 85.48%
  );
  border-radius: 15px 0px 15px 15px;
  padding: 18px;
}
.card {
  border: 1.5px solid #ffffff;
  background: rgba(255, 255, 255, 0.25);
  width: 185px;
  backdrop-filter: blur(45px);
  -webkit-backdrop-filter: blur(45px);
  border-radius: 15px 0px 15px 15px;
  text-align: center;
}

.create-btn {
  background: linear-gradient(#d8f5a2, #3e9c75);
  /* background:linear-gradient(
    92.04deg,
    #98a2ff -0.86%,
    #c29dff 48.52%,
    #f198ff 100%
  ); */
  border-radius: 46px;
  font-size: 22px;
  padding: 8px 24px;
  color: #ffffff;
  font-family: Nunito;
  font-weight: 800;
  border: none;
}

.right-card > p {
  padding-bottom: 18px;
  color: #635198;
  font-family: Source Sans Pro;
  font-weight: 400;
}

.right-card > h1 {
  font-weight: 800;
  font-family: Nunito;
  font-size: 26px;
}

.right-card > div > div {
  font-weight: 400;
  font-family: Nunito;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.right-card > div > p {
  color: #635198;
  font-family: Source Sans Pro;
  font-weight: 600;
  font-size: 24px;
}

.inner-card > h1 {
  font-weight: 800;
  font-family: Nunito;
  font-size: 26px;
  margin: 20px;
}

.card > h4 {
  font-weight: 900;
  font-family: Nunito;
  font-size: 23px;
  color: #927cc8;
  margin: -10px 0px;
}

.title-para {
  font-weight: 900;
  font-family: Nunito;
  font-size: 20px;
  color: #927cc8;
  width: 100%;
  height: 50px;
}

.text-content-inner-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.sliderContainer {
  width: 400px;
  height: 50px;
  background-color: white;
  display: flex;
  justify-content: space-around;
  position: relative;
  border-radius: 40px;
  cursor: pointer;
  color: #635198;
}

.slideRight {
  position: absolute;
  width: 200px;
  height: 100%;
  background: linear-gradient(
    92.04deg,
    #98a2ff -0.86%,
    #c29dff 48.52%,
    #f198ff 100%
  );
  -webkit-animation: slideToRight 0.5s;
          animation: slideToRight 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  border-radius: 40px;
}

@-webkit-keyframes slideToRight {
  from {
    left: 0px;
  }
  to {
    left: 200px;
  }
}

@keyframes slideToRight {
  from {
    left: 0px;
  }
  to {
    left: 200px;
  }
}

.slideLeft {
  position: absolute;
  width: 200px;
  height: 100%;
  background: linear-gradient(
    92.04deg,
    #98a2ff -0.86%,
    #c29dff 48.52%,
    #f198ff 100%
  );
  -webkit-animation: slideToLeft 0.5s;
          animation: slideToLeft 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  border-radius: 40px;
}

@-webkit-keyframes slideToLeft {
  from {
    right: 0px;
  }
  to {
    right: 200px;
  }
}

@keyframes slideToLeft {
  from {
    right: 0px;
  }
  to {
    right: 200px;
  }
}

.test-toggle-font {
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 41px;
  text-align: center;
  letter-spacing: 0.02em;
}

.leaderboard-username {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 33px;
  color: #000000;
}

.leaderboard-points {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 25px;
  color: #635198;
}

.test-success-left-top-card {
  -webkit-backdrop-filter: blur(45px);
          backdrop-filter: blur(45px);
  border: 1.5px solid #ffffff;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 15px 0px 15px 15px;
  padding: 20px;
}

.test-success-left-bottom-card {
  margin-bottom: 20px;
  -webkit-backdrop-filter: blur(45px);
          backdrop-filter: blur(45px);
  border: 1.5px solid #ffffff;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 15px 0px 15px 15px;
  margin-top: 30px;
  padding: 25px;
  text-align: left;
}

.test-success-right-card {
  -webkit-backdrop-filter: blur(45px);
          backdrop-filter: blur(45px);
  border: 1.5px solid #ffffff;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 15px 0px 15px 15px;
  padding: 20px;
}

.success-left-top-title {
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 27px;
  line-height: 48px;
  text-align: center;
  color: #000000;
  margin-right: 8px;
}

.success-left-top-stats-title {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 32px;
  text-align: center;
  color: #000000;
  margin-left: 10px;
}

.success-left-top-stats-value {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 55px;
  color: #635198;
}

.success-left-top-stats-unit {
  font-family: Source Sans Pro;
  font-style: normal;
  font-size: 20px;
  line-height: 55px;
  color: #635198;
}

.success-accuracy-title {
  font-family: Source Sans Pro;
  font-style: normal;
  font-size: 20px;
  line-height: 33px;
  color: #635198;
  text-align: center;
  padding-bottom: 10px;
}

.test-left-bottom-title {
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: 0.02em;
  color: #000000;
  margin-bottom: 10px;
}

.test-left-bottom-description {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #635198;
  margin-bottom: 25px;
}

.test-success-right-title {
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 41px;
  text-align: left;
  letter-spacing: 0.02em;
  color: #000000;
}

.test-success-column-name {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: #000000;
}

.test-success-column-name > th {
  text-align: left;
}

.test-success-column-value {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #635198;
}

.test-success-column-value > td {
  text-align: left;
}

.test-leader-title {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 32px;
  text-align: center;
  color: #000000;
}
.test-leader-value {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 45px;
  color: #635198;
}

.try-text {
  font-size: 18px;
  font-weight: 600;
  transition: all 0.4s ease-in-out;
  background-image: linear-gradient(
    to right,
    #49A278,
    #49A278 50%,
    #927cc8 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.try-text:hover {
  background-position: 0;
}
.try-text:hover::before {
  width: 100%;
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .main-card {
    width: 90% !important;
  }
  .upcoming-competition {
    justify-content: center;
  }
}

.login-font-heading {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 55px;
  color: #ff0000;
}

.login-font-description {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 49px;
  letter-spacing: 0.03em;
  color: #574f48;
}

.login-form-container {
  width: 40%;
  background: linear-gradient(
    114.83deg,
    rgba(255, 255, 255, 0.5) 0.8%,
    rgba(255, 255, 255, 0) 101.31%
  );
  border-radius: 30px;
  padding: 30px;
}

.login-form-title {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 48px;
  color: #ff0000;
  text-align: center;
  margin: 15px 0;
}

.login-input-container {
  position: relative;
  background-color: #f9f9f9;
  margin-bottom: 20px;
  padding: 15px;
}

.login-input-container > span {
  margin-right: 14px;
}

.login-input-container > input {
  border: none;
  background-color: #f9f9f9;
  width: 88%;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  color: dimgrey;
}

.login-input-container > input:focus {
  outline: none !important;
}

.input-error {
  border: 1px solid red;
}

.inputs-container {
  margin-top: 30px;
}

.login-helper-font {
  font-family: Nunito;
  font-weight: 500;
  color: black;
  text-align: left;
  font-size: 20px;
  margin-top: 35px;
}

.login-bottom-font > span {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #000;
}

.login-bottom-font {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 15px;
  color: #ff0000;
  text-align: center;
}

.google-signin-button:active {
  background-color: red !important;
  color: white !important;
}

.google-signin-button {
  background-color: white !important;
  color: red !important;
  border-radius: 10px !important;
  border: 1px solid rgb(255, 0, 0) !important;
  width: 100% !important;
  padding: 4px !important;
  line-height: 30px !important;
  font-size: 26px !important;
  margin-bottom: 50px !important;
  cursor: pointer !important;
  justify-content: center !important;
}

.profile-background {
  background-color: #fff;
}

.profile-background-image {
  background-image: url(/static/media/profile-background.fe1321d9.png);
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
}

.user-profile-container {
  width: 80%;
  margin: auto;
  padding: 10px;
  text-align: center;
  margin-top: 5%;
}

.profile-title-block {
  text-align: center;
  margin-bottom: 10%;
}

.profile-form-block {
  width: 80%;
  margin: auto;
}

.profile-form-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.profile-form-container > div {
  text-align: left;
  border-bottom: 1px solid #c1c1c1;
  margin-bottom: 50px;
}

.profile-label {
  margin-bottom: 15px;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #c1c1c1;
}

.profile-input {
  border: none;
  outline: none;
  width: 400px;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.02em;
  color: #000000;
  background-color: white;
}

.title-font {
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 41px;
  letter-spacing: 0.02em;
  color: #ff0000;
}

.title-description {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: 0.02em;
  color: #5f5e5e;
}

.profile-circle {
  width: 100px;
  border-radius: 50%;
  background-color: #fcd2d1;
  color: #ff0000;
  border-radius: 50%;
  height: 100px;
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 70px;
  line-height: 41px;
  margin: 75px auto 0;
}

.reset-password-container {
  width: 50%;
  margin: 35px auto 0;
  padding: 10px;
  text-align: center;
}

.reset-password-form-container {
  text-align: center;
}

.reset-password-form-container > div {
  text-align: left;
}

.reset-password-form-container > div {
  text-align: left;
  border-bottom: 1px solid #c1c1c1;
  margin-bottom: 50px;
}

.reset-form-block {
  width: 50%;
  margin: 0 auto 100px;
}
@media only screen and (min-width: 320px) and (max-width: 639px) {
  .user-profile-container {
    width: 100%;
  }
  .profile-input {
    width: 315px;
  }
}
.test-form-container {
  border: 1.5px solid #ffffff;
  box-sizing: border-box;
  /* background: rgba(255, 255, 255, 0.2); */
  background: linear-gradient(
    106.25deg,
    rgba(255, 255, 255, 0.48) 5.86%,
    rgba(255, 255, 255, 0.276) 85.48%
  );
  border-radius: 15px;
  width: 80%;
  margin: 0 auto;
  padding: 35px;
  position: relative;
}

.test-form-description {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #635198;
  margin-bottom: 25px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.time-limits-container {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
  flex-direction: row;
  margin-bottom: 30px;
}

.test-form-title {
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #000000;
  margin: 0px auto 10px;
}

.test-content {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 45px;
  letter-spacing: 0.13em;
  color: #635198;
  margin: 35px 0;
  display: flex;
  flex-wrap: wrap;
  max-height: 300px;
  overflow: hidden;
  height: 235px;
}

.select-timelimit-font {
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #000000;
  margin-bottom: 20px;
  text-align: left;
}

.time-limit-button {
  width: 195px;
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 41px;
  text-align: center;
  letter-spacing: 0.02em;
  border-radius: 45px;
  color: #313149;
  padding: 10px;
  display: inline-block;
  margin: 0;
  position: relative;
  z-index: 0;
  cursor: pointer;
}

.time-limit-button::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2px;
  border-radius: 40px;
  background: linear-gradient(
    to right,
    #98a2ff -0.86%,
    #c29dff 48.52%,
    #f198ff 100%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.time-limit-button-text {
  background: -webkit-linear-gradient(
    to bottom,
    #98a2ff -0.86%,
    #c29dff 48.52%,
    #f198ff 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.test-type-container {
  text-align: center;
  margin-bottom: 30px;
}

.create-first-input-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap: 70px;
  gap: 70px;
  margin-bottom: 30px;
  align-items: center;
}

.create-first-input-section > input {
  padding: 15px;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  text-align: left;
  letter-spacing: 0.02em;
  position: relative;
  height: 50px;
  border: 1px solid lightgray;
  color: black;
}

.create-first-input-section > select {
  width: 450px;
  background: none;
  padding: 15px;
  border-radius: 40px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  text-align: left;
  letter-spacing: 0.02em;
  color: #635198;
  position: relative;
  z-index: 0;
}

.create-first-input-section > input::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2px;
  border-radius: 40px;
}

.create-first-input-section > select::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2px;
  border-radius: 40px;
}

.create-textarea-container {
  margin-bottom: 30px;
}

.create-textarea-container {
  margin: 30px 0px;
}

.create-textarea-container > textarea {
  width: 100%;
}

.create-submit {
  text-align: center;
}

.test-stats-container {
  display: flex;
  justify-content: space-between;
}

.test-stats-title {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 32px;
  text-align: center;
  color: #000000;
  margin-left: 5px;
}

.test-stats-value {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 55px;
  color: #635198;
}

.test-stats-unit {
  font-size: 18px;
  font-weight: 400;
}

.test-success-card {
  position: absolute;
  width: 700px;
  left: 200px;
  top: 270px;
  padding: 30px;
  background: #ffffff;
  box-sizing: border-box;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  border-radius: 15px;
  border: "2px solid";
  z-index: 1;
  border: 1px solid lightgrey;
}

.success-card-title {
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #000000;
}

.success-card-description {
  margin-bottom: 30px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: #635198;
}

.test-card-title-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.test-link-container {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #000000;
  background: #e6e3e3;
  padding: 0px 10px;
  border-radius: 15px 0px 15px 15px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.test-link-container > .copy-img {
  width: 50px;
}

.test-link-container > .copy-img > img {
  width: 28px;
  padding: 10px 0px;
}

.test-link-line {
  height: 100%;
  border: 1px solid #635198;
  position: absolute;
  right: 60px;
  top: 0;
}

.test-link-line > img {
  cursor: pointer;
}

