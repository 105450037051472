.login-font-heading {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 55px;
  color: #ff0000;
}

.login-font-description {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 49px;
  letter-spacing: 0.03em;
  color: #574f48;
}

.login-form-container {
  width: 40%;
  background: linear-gradient(
    114.83deg,
    rgba(255, 255, 255, 0.5) 0.8%,
    rgba(255, 255, 255, 0) 101.31%
  );
  border-radius: 30px;
  padding: 30px;
}

.login-form-title {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 48px;
  color: #ff0000;
  text-align: center;
  margin: 15px 0;
}

.login-input-container {
  position: relative;
  background-color: #f9f9f9;
  margin-bottom: 20px;
  padding: 15px;
}

.login-input-container > span {
  margin-right: 14px;
}

.login-input-container > input {
  border: none;
  background-color: #f9f9f9;
  width: 88%;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  color: dimgrey;
}

.login-input-container > input:focus {
  outline: none !important;
}

.input-error {
  border: 1px solid red;
}

.inputs-container {
  margin-top: 30px;
}

.login-helper-font {
  font-family: Nunito;
  font-weight: 500;
  color: black;
  text-align: left;
  font-size: 20px;
  margin-top: 35px;
}

.login-bottom-font > span {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #000;
}

.login-bottom-font {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 15px;
  color: #ff0000;
  text-align: center;
}

.google-signin-button:active {
  background-color: red !important;
  color: white !important;
}

.google-signin-button {
  background-color: white !important;
  color: red !important;
  border-radius: 10px !important;
  border: 1px solid rgb(255, 0, 0) !important;
  width: 100% !important;
  padding: 4px !important;
  line-height: 30px !important;
  font-size: 26px !important;
  margin-bottom: 50px !important;
  cursor: pointer !important;
  justify-content: center !important;
}
