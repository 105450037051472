.right-rest-to-rest-container {
  position: absolute !important;
  top: 14% !important;
  left: 40% !important;
  width: 100% !important;
}

.right-rest {
  width: 60% !important;
  opacity: 0.8;
}

.right-rest-to-space-container {
  position: absolute !important;
  top: 13% !important;
  left: 38% !important;
  width: 100% !important;
}

.right-space {
  width: 61% !important;
  opacity: 0.8;
}

.right-rest-to-y-container {
  position: absolute !important;
  top: -9% !important;
  left: 31% !important;
  width: 100% !important;
}

.right-y {
  width: 62% !important;
  opacity: 0.8;
}

.right-rest-to-u-container {
  position: absolute !important;
  top: 4% !important;
  left: 35% !important;
  width: 100% !important;
}

.right-u {
  width: 62% !important;
  opacity: 0.8;
}

.right-rest-to-i-container {
  position: absolute !important;
  top: 4% !important;
  left: 37% !important;
  width: 100% !important;
}

.right-i {
  width: 65% !important;
  opacity: 0.8;
}

.right-rest-to-o-container {
  position: absolute !important;
  top: 7% !important;
  left: 39% !important;
  width: 100% !important;
}

.right-o {
  width: 64% !important;
  opacity: 0.8;
}

.right-rest-to-p-container {
  position: absolute !important;
  top: 3% !important;
  left: 41% !important;
  width: 100% !important;
}

.right-p {
  width: 65% !important;
  opacity: 0.8;
}

.right-rest-to-h-container {
  position: absolute !important;
  top: 12% !important;
  left: 36% !important;
  width: 100% !important;
}

.right-h {
  width: 63% !important;
  opacity: 0.8;
}

.right-rest-to-j-container {
  position: absolute !important;
  top: 14% !important;
  left: 37% !important;
  width: 100% !important;
}

.right-j {
  width: 63% !important;
  opacity: 0.8;
}

.right-rest-to-k-container {
  position: absolute !important;
  top: 10% !important;
  left: 37% !important;
  width: 100% !important;
}

.right-k {
  width: 65% !important;
  opacity: 0.8;
}

.right-rest-to-l-container {
  position: absolute !important;
  top: 12% !important;
  left: 31% !important;
  width: 100% !important;
}

.right-l {
  width: 64% !important;
  opacity: 0.8;
}

.right-rest-to-semicolon-container {
  position: absolute !important;
  top: 10% !important;
  left: 38% !important;
  width: 100% !important;
}

.right-semicolon {
  width: 64% !important;
  opacity: 0.8;
}

.right-rest-to-n-container {
  position: absolute !important;
  top: 8% !important;
  left: 40% !important;
  width: 100% !important;
}

.right-n {
  width: 65% !important;
  opacity: 0.8;
}

.right-rest-to-m-container {
  position: absolute !important;
  top: 9% !important;
  left: 43% !important;
  width: 100% !important;
}

.right-m {
  width: 70% !important;
  opacity: 0.8;
}

.right-rest-to-comma-container {
  position: absolute !important;
  top: -17% !important;
  left: 34% !important;
  width: 100% !important;
}

.right-comma {
  width: 72% !important;
  opacity: 0.8;
}

.right-rest-to-dot-container {
  position: absolute !important;
  top: -14% !important;
  left: 35% !important;
  width: 100% !important;
}

.right-dot {
  width: 69% !important;
  opacity: 0.8;
}

.right-rest-to-slash-container {
  position: absolute !important;
  top: -10% !important;
  left: 34% !important;
  width: 100% !important;
}

.right-slash {
  width: 68% !important;
  opacity: 0.8;
}

.right-rest-to-6-container, .right-rest-to-caret-container {
  position: absolute !important;
  top: -25% !important;
  left: 22% !important;
  width: 100% !important;
}

.right-6, .right-caret {
  width: 68% !important;
  opacity: 0.8;
}

.right-rest-to-7-container, .right-rest-to-ampersand-container {
  position: absolute !important;
  top: -19% !important;
  left: 27% !important;
  width: 100% !important;
}

.right-7, .right-ampersand {
  width: 60% !important;
  opacity: 0.8;
}

.right-rest-to-8-container, .right-rest-to-asterisk-container {
  position: absolute !important;
  top: -24% !important;
  left: 29% !important;
  width: 100% !important;
}

.right-8, .right-asterisk {
  width: 60% !important;
  opacity: 0.9;
}

.right-rest-to-9-container, .right-rest-to-open-container {
  position: absolute !important;
  top: -19% !important;
  left: 26% !important;
  width: 100% !important;
}

.right-9, .right-open {
  width: 68% !important;
  opacity: 0.9;
}

.right-rest-to-0-container, .right-rest-to-close-container {
  position: absolute !important;
  top: -25% !important;
  left: 28% !important;
  width: 100% !important;
}

.right-0, .right-close {
  width: 70% !important;
  opacity: 0.8;
}
@media only screen and (min-width: 320px) and (max-width: 640px) {
 
}