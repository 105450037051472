.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: rgb(122, 3, 3);
  left: 50%;
  top: 50%;
  z-index: 1;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.my-root {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.root-container {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
}

div.root-container {
  padding: 20px;
}

.background-image {
  background-image: url("https://docs-assets.katomaran.tech/images/typing/lesson/2022/02/Frame_3.png");
  margin: 0;
  background-attachment: fixed;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: auto;
}
.main-card {
  padding: 50px 30px;
  border-radius: 32px;
  background-attachment: fixed;
  width: 80%;
  box-sizing: border-box;
  margin: auto;
}
.inner-card {
  backdrop-filter: blur(45px);
  border: 1.5px solid #ffffff;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 15px 0px 15px 15px;
}
.right-card {
  backdrop-filter: blur(45px);
  border: 1.5px solid #ffffff;
  box-sizing: border-box;
  /* background: rgba(255, 255, 255, 0.25); */
  background: linear-gradient(
    106.25deg,
    rgba(255, 255, 255, 0.48) 5.86%,
    rgba(255, 255, 255, 0.276) 85.48%
  );
  border-radius: 15px 0px 15px 15px;
  padding: 18px;
}
.card {
  border: 1.5px solid #ffffff;
  background: rgba(255, 255, 255, 0.25);
  width: 185px;
  backdrop-filter: blur(45px);
  -webkit-backdrop-filter: blur(45px);
  border-radius: 15px 0px 15px 15px;
  text-align: center;
}

.create-btn {
  background: linear-gradient(#d8f5a2, #3e9c75);
  /* background:linear-gradient(
    92.04deg,
    #98a2ff -0.86%,
    #c29dff 48.52%,
    #f198ff 100%
  ); */
  border-radius: 46px;
  font-size: 22px;
  padding: 8px 24px;
  color: #ffffff;
  font-family: Nunito;
  font-weight: 800;
  border: none;
}

.right-card > p {
  padding-bottom: 18px;
  color: #635198;
  font-family: Source Sans Pro;
  font-weight: 400;
}

.right-card > h1 {
  font-weight: 800;
  font-family: Nunito;
  font-size: 26px;
}

.right-card > div > div {
  font-weight: 400;
  font-family: Nunito;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.right-card > div > p {
  color: #635198;
  font-family: Source Sans Pro;
  font-weight: 600;
  font-size: 24px;
}

.inner-card > h1 {
  font-weight: 800;
  font-family: Nunito;
  font-size: 26px;
  margin: 20px;
}

.card > h4 {
  font-weight: 900;
  font-family: Nunito;
  font-size: 23px;
  color: #927cc8;
  margin: -10px 0px;
}

.title-para {
  font-weight: 900;
  font-family: Nunito;
  font-size: 20px;
  color: #927cc8;
  width: 100%;
  height: 50px;
}

.text-content-inner-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.sliderContainer {
  width: 400px;
  height: 50px;
  background-color: white;
  display: flex;
  justify-content: space-around;
  position: relative;
  border-radius: 40px;
  cursor: pointer;
  color: #635198;
}

.slideRight {
  position: absolute;
  width: 200px;
  height: 100%;
  background: linear-gradient(
    92.04deg,
    #98a2ff -0.86%,
    #c29dff 48.52%,
    #f198ff 100%
  );
  animation: slideToRight 0.5s;
  animation-fill-mode: forwards;
  border-radius: 40px;
}

@keyframes slideToRight {
  from {
    left: 0px;
  }
  to {
    left: 200px;
  }
}

.slideLeft {
  position: absolute;
  width: 200px;
  height: 100%;
  background: linear-gradient(
    92.04deg,
    #98a2ff -0.86%,
    #c29dff 48.52%,
    #f198ff 100%
  );
  animation: slideToLeft 0.5s;
  animation-fill-mode: forwards;
  border-radius: 40px;
}

@keyframes slideToLeft {
  from {
    right: 0px;
  }
  to {
    right: 200px;
  }
}

.test-toggle-font {
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 41px;
  text-align: center;
  letter-spacing: 0.02em;
}

.leaderboard-username {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 33px;
  color: #000000;
}

.leaderboard-points {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 25px;
  color: #635198;
}

.test-success-left-top-card {
  backdrop-filter: blur(45px);
  border: 1.5px solid #ffffff;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 15px 0px 15px 15px;
  padding: 20px;
}

.test-success-left-bottom-card {
  margin-bottom: 20px;
  backdrop-filter: blur(45px);
  border: 1.5px solid #ffffff;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 15px 0px 15px 15px;
  margin-top: 30px;
  padding: 25px;
  text-align: left;
}

.test-success-right-card {
  backdrop-filter: blur(45px);
  border: 1.5px solid #ffffff;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 15px 0px 15px 15px;
  padding: 20px;
}

.success-left-top-title {
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 27px;
  line-height: 48px;
  text-align: center;
  color: #000000;
  margin-right: 8px;
}

.success-left-top-stats-title {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 32px;
  text-align: center;
  color: #000000;
  margin-left: 10px;
}

.success-left-top-stats-value {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 55px;
  color: #635198;
}

.success-left-top-stats-unit {
  font-family: Source Sans Pro;
  font-style: normal;
  font-size: 20px;
  line-height: 55px;
  color: #635198;
}

.success-accuracy-title {
  font-family: Source Sans Pro;
  font-style: normal;
  font-size: 20px;
  line-height: 33px;
  color: #635198;
  text-align: center;
  padding-bottom: 10px;
}

.test-left-bottom-title {
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: 0.02em;
  color: #000000;
  margin-bottom: 10px;
}

.test-left-bottom-description {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #635198;
  margin-bottom: 25px;
}

.test-success-right-title {
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 41px;
  text-align: left;
  letter-spacing: 0.02em;
  color: #000000;
}

.test-success-column-name {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: #000000;
}

.test-success-column-name > th {
  text-align: left;
}

.test-success-column-value {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #635198;
}

.test-success-column-value > td {
  text-align: left;
}

.test-leader-title {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 32px;
  text-align: center;
  color: #000000;
}
.test-leader-value {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 45px;
  color: #635198;
}

.try-text {
  font-size: 18px;
  font-weight: 600;
  transition: all 0.4s ease-in-out;
  background-image: linear-gradient(
    to right,
    #49A278,
    #49A278 50%,
    #927cc8 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.try-text:hover {
  background-position: 0;
}
.try-text:hover::before {
  width: 100%;
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .main-card {
    width: 90% !important;
  }
  .upcoming-competition {
    justify-content: center;
  }
}
