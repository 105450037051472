.profile-background {
  background-color: #fff;
}

.profile-background-image {
  background-image: url("../images/photos/profile-background.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
}

.user-profile-container {
  width: 80%;
  margin: auto;
  padding: 10px;
  text-align: center;
  margin-top: 5%;
}

.profile-title-block {
  text-align: center;
  margin-bottom: 10%;
}

.profile-form-block {
  width: 80%;
  margin: auto;
}

.profile-form-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.profile-form-container > div {
  text-align: left;
  border-bottom: 1px solid #c1c1c1;
  margin-bottom: 50px;
}

.profile-label {
  margin-bottom: 15px;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #c1c1c1;
}

.profile-input {
  border: none;
  outline: none;
  width: 400px;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.02em;
  color: #000000;
  background-color: white;
}

.title-font {
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 41px;
  letter-spacing: 0.02em;
  color: #ff0000;
}

.title-description {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: 0.02em;
  color: #5f5e5e;
}

.profile-circle {
  width: 100px;
  border-radius: 50%;
  background-color: #fcd2d1;
  color: #ff0000;
  border-radius: 50%;
  height: 100px;
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 70px;
  line-height: 41px;
  margin: 75px auto 0;
}

.reset-password-container {
  width: 50%;
  margin: 35px auto 0;
  padding: 10px;
  text-align: center;
}

.reset-password-form-container {
  text-align: center;
}

.reset-password-form-container > div {
  text-align: left;
}

.reset-password-form-container > div {
  text-align: left;
  border-bottom: 1px solid #c1c1c1;
  margin-bottom: 50px;
}

.reset-form-block {
  width: 50%;
  margin: 0 auto 100px;
}
@media only screen and (min-width: 320px) and (max-width: 639px) {
  .user-profile-container {
    width: 100%;
  }
  .profile-input {
    width: 315px;
  }
}